





























































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import {IBookmark} from '@/services/api/bookmark.service';

import Badge from '@/components/UI/Badge.vue';
import BookmarksActions from '@/pages/Bookmarks/BookmarksActions.vue';

import DotsIcon from '@/assets/icon/comp-dots-horizontal.svg';

import relativeTime from '@/filters/relativeTime';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: {
    BookmarksActions,
    DotsIcon,
    Badge
  },
  computed: {...mapFields([
      'editMode'
    ]),
  },
  filters: {relativeTime}
})

export default class BookmarkItem extends Vue {
  private isActive: boolean = false;
  private editMode!: boolean;
  
  @Prop({
    type: Object,
    required: true,
  })
  public data!: IBookmark;

  public openMenu(): void {
    this.isActive = true;
  }

  public closeMenu(): void {
    this.isActive = false;
  }

  public loadBookmark(id: number): void {
    this.$emit('setEditModeAnimated', false);
    this.$store.dispatch('getMediaListById', {id, loader: true});
    if (this.$store.getters.isMobileAdaptiveMode) {
      this.$emit('openModal', 'bookmarkDetailModal', this.data);
    }
  }

  public dateTitle(durationSeconds: number) {
    if (!this.$options.filters || !durationSeconds) return '';
    return durationSeconds > 5
      ? `Vor ${this.$options.filters.relativeTime(durationSeconds)} bearbeitet` 
      : `${this.$options.filters.relativeTime(durationSeconds)} bearbeitet`;
  }
}
