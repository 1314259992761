





















































import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import {SortDirection} from '@/interfaces/interfaces';
import {IBookmark} from '@/services/api/bookmark.service';
import {InputActionData, InputActionType} from '@/interfaces/interfaces';

import BackLink from '@/components/UI/BackLink.vue';
import Sort from '@/components/UI/Sort.vue';
import BookmarkItem from '@/pages/Bookmarks/BookmarkItem.block.vue';
import Badge from '@/components/UI/Badge.vue';
import BookmarksModals from '@/pages/Bookmarks/BookmarksModals.vue';
import BookmarkMediaList from '@/pages/Bookmarks/BookmarkMediaList.vue';
import BookmarkActionPanel from '@/pages/Bookmarks/BookmarkActionPanel.vue';

import PlusIcon from '@/assets/icon/ico-plus.svg';
import SearchIcon from '@/assets/icon/search.svg';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: {
    BackLink,
    BookmarkItem,
    Sort,
    Badge,
    BookmarksModals,
    BookmarkMediaList,
    BookmarkActionPanel
  },
  computed: {...mapFields([
      'bookmarkList',
      'search',
      'sortDirection',
      'activeBookmarkIdList',
      'mediaIdList'
    ]),
  }
})

export default class BookmarksList extends Vue {
  public PlusIcon = PlusIcon;
  public SearchIcon = SearchIcon;

  public bookmarkList!: IBookmark[];
  public search!: string;
  public sortDirection!: SortDirection;
  public mediaIdList!: number[];

  private created(): void {
    this.$store.dispatch('getBookmarkData');
  }

  public get bookmarksCount(): number {
    return this.bookmarkList.length;
  }
  
  public actionList: InputActionData[] = [
    InputActionType.clear,
    {
      position: 'left',
      icon: SearchIcon,
      className: 'search',
      title: 'Suchen',
    }
  ]

  public updateSearch(value: string): void {
    this.$store.dispatch('searchBookmark', value);
  }

  public toggleSort(): void {
    this.$store.dispatch('toggleBookmarkSort');
  }

  public setEditModeAnimated(value: boolean) {
    this.$store.dispatch('setEditModeAnimated', value);
  }
}
