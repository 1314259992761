

















import Vue from 'vue';
import Component from 'vue-class-component';
import adaptiveButtonSize from '@/mixins/adaptiveButtonSize';

@Component({
  mixins: [adaptiveButtonSize]
})

export default class BookmarksForm extends Vue {
  public removeBookmark(): void {
    this.$store.dispatch('removeBookmark', {id: this.$attrs.collectionId})
      .then(() => this.$emit('closeModal'));
  }

  public cancel(): void {
    this.$emit('closeModal');
  }
}
