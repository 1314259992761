import { render, staticRenderFns } from "./BookmarksList.section.vue?vue&type=template&id=4970d016&scoped=true&lang=pug&"
import script from "./BookmarksList.section.vue?vue&type=script&lang=ts&"
export * from "./BookmarksList.section.vue?vue&type=script&lang=ts&"
import style0 from "./BookmarksList.section.vue?vue&type=style&index=0&id=4970d016&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4970d016",
  null
  
)

export default component.exports