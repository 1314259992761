





































import {
  Vue,
  Component
} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';

import EditIcon from '@/assets/icon/edit.svg';
import DeleteIcon from '@/assets/icon/ico-delete.svg';
import MoveIcon from '@/assets/icon/verschieben.svg';
import CloseIcon from '@/assets/icon/input-close.svg';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  computed: {
    ...mapFields([
      'mediaIdList',
      'isAllMediaListSelected',
    ])
  }
})
export default class BookmarkActionMenu extends Vue {
  public EditIcon = EditIcon;
  public DeleteIcon = DeleteIcon;
  public MoveIcon = MoveIcon;
  public CloseIcon = CloseIcon;

  public mediaIdList!: number[];
  private isNextStepShowActions: boolean = false;

  public closeMenu(){
    this.$store.dispatch('toggleEditMode');
    this.isNextStepShowActions = false;
  }

  public replaceMedia() {
    if (this.mediaIdList[0]) {
      this.$parent.$emit('openModal', 'replaceMedia', undefined);
    }
  }

  public removeMedia() {
    if (this.mediaIdList[0]) {
      this.$store.dispatch('removeMedia');
    }
  }
}
