





































import {
  Vue,
  Component
} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import BookmarksModals from '@/pages/Bookmarks/BookmarksModals.vue';

import EditIcon from '@/assets/icon/edit.svg';
import DeleteIcon from '@/assets/icon/ico-delete.svg';
import MoveIcon from '@/assets/icon/verschieben.svg';
import CloseIcon from '@/assets/icon/input-close.svg';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: { BookmarksModals },
  computed: {
    ...mapFields([
      'mediaIdList',
      'editMode',
      'isAllMediaListSelected',
      'editModeAnimated'
    ])
  }
})
export default class BookmarkActionPanel extends Vue {
  public EditIcon = EditIcon;
  public DeleteIcon = DeleteIcon;
  public MoveIcon = MoveIcon;
  public CloseIcon = CloseIcon;

  public mediaIdList!: number[];

  public setAllMediaList(): void {
    this.$store.dispatch('setAllMediaIdList');
  }

  public toggleEditMode(): void {
    this.$store.dispatch('setEditModeAnimated', true);
    this.$store.dispatch('toggleEditMode');
  }

  public removeMedia() {
    this.mediaIdList[0]
      ? this.$store.dispatch('removeMedia')
      : this.notifyMediaNotSelected();
  }

  public replaceMedia() {
    this.mediaIdList[0]
      ? (this.$parent.$refs.modals as BookmarksModals).openModal('replaceMedia', undefined)
      : this.notifyMediaNotSelected();
  }

  public notifyMediaNotSelected() {
    this.$notify({
      group: 'app',
      type: 'warning',
      title: 'Merklisten',
      text: 'Bitte wählen Sie die gewünschten Medien'
    });
  }

}
