







import { Vue, Component } from 'vue-property-decorator';
import BookmarkActionPanel from '@/pages/Bookmarks/BookmarkActionPanel.vue';
import BookmarkMediaList from '@/pages/Bookmarks/BookmarkMediaList.vue';
import BookmarkActionMenu from '@/pages/Bookmarks/BookmarkActionMenu.vue';

@Component({
  components: {
    BookmarkActionPanel,
    BookmarkMediaList,
    BookmarkActionMenu
  }
})
export default class BookmarkDetailModal extends Vue {

}
