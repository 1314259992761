






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {SortDirection} from '@/interfaces/interfaces';

@Component({})
export default class Arrow extends Vue {

  @Prop({
    type: String,
    required: true,
    default: null
  })
  public direction!: SortDirection;
}
