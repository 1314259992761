


























































































import Vue from 'vue';
import dayjs from 'dayjs';
import Component from 'vue-class-component';
import {IBookmark} from '@/services/api/bookmark.service';
import Textarea from '@/components/UI/Textarea.vue';
import DateIcon from '@/assets/icon/ico-date.svg';
import QrcodeVue from 'qrcode.vue';
import InputLink from '@/components/UI/InputLink.vue';

@Component({
  components: {
    Textarea,
    DateIcon,
    QrcodeVue,
    InputLink
  }
})

export default class ShareBookmarkForm extends Vue {
  public today: string = dayjs().format('YYYY-MM-DD');
  public max: string = this.$store.getters.getUserMaxShareDate;
  public min: string = this.today;

  public from: string = dayjs(this.max).isBefore(this.today) ? this.max : this.today;
  public to: string = this.max;
  public description: string = '';
  public isDateInputsVisible: boolean = false;
  public isModalBookmarkShareLoading!: boolean;
  public bookmarkId = this.$attrs.bookmarkIdList[0];

  public get bookmark(): IBookmark {
    return this.$store.getters.getBookmarkById(this.bookmarkId);
  }

  public get shareLink(): string {
    return this.$store.getters.getBookmarkShareLink;
  }

  public get dateInterval(): string {
    return !!this.from.length && !!this.to.length
      ? `Von <b>${dayjs(this.from).format('DD.MM.YYYY')}</b> bis <b>${dayjs(this.to).format('DD.MM.YYYY')}</b>`
      : '';
  }

  public nextStep(): void {
    this.$store.dispatch(this.$attrs.action, {
      ...this.bookmark,
      description: this.description ? this.description : null, 
      from: this.from && this.isDateInputsVisible ? this.from : null,
      to: this.to && this.isDateInputsVisible ? this.to : null
    })
      .then(() => {
        this.$emit('nextStep');
      });
  }

  public prevStep(): void {
    this.$emit('prevStep');
  }

  public print() {
    window.print();
  }
}
