







import Vue from 'vue';
import Component from 'vue-class-component';
import List from '@/pages/Bookmarks/BookmarksList.section.vue';
import Chart from '@/pages/Main/Chart.section.vue';
import CardSlider from '@/pages/Main/Card.slider.section.vue';

@Component({
  components: {
    List,
    Chart,
    CardSlider
  }
})
export default class Details extends Vue {}
