









































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IBookmark} from '@/services/api/bookmark.service';

import Permission from '@/components/Permission/Permission.vue';

import ShareIcon from '@/assets/icon/ico-freigabe.svg';
import EditIcon from '@/assets/icon/edit.svg';
import RenameIcon from '@/assets/icon/ico-rename.svg';
import DeleteIcon from '@/assets/icon/delete.svg';

@Component({
  components: {
    Permission,
    ShareIcon,
    EditIcon,
    RenameIcon,
    DeleteIcon,
  }
})

export default class BookmarksActions extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public data!: IBookmark;

  public shareBookmark() {
    this.$emit('openModal', 'shareBookmark', this.data);
    this.$emit('close');
  }

  public editBookmark(): void {
    this.$store.dispatch('getMediaListById', {id: this.data.collectionId, loader: true})
      .then(() => this.$store.commit('TOGGLE_EDIT_MODE', true));
    this.$emit('close');

    if (this.$store.getters.isMobileAdaptiveMode) {
      this.$emit('openModal', 'bookmarkDetailModal', this.data);
    }
  }

  public renameBookmark() {
    this.$emit('openModal', 'renameBookmark', this.data);
    this.$emit('close');
  }

  public removeBookmark() {
    this.$emit('openModal', 'removeBookmark', this.data);
    this.$emit('close');
  }
}
