import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export default (durationSeconds: number) => {
  const duration = dayjs.duration(durationSeconds, 'seconds') || 0;

  if (duration.years() > 1) {
    return `${duration.years()} Jahren`;
  }
  if (duration.years() > 0) {
    return `${duration.years()} Jahr`;
  }
  if (duration.months() > 1) {
    return `${duration.months()} Monaten`;
  }
  if (duration.months() > 0) {
    return `${duration.months()} Monat`;
  }
  if (duration.weeks() > 1) {
    return `${duration.weeks()} Wochen`;
  }
  if (duration.weeks() > 0) {
    return `${duration.weeks()} Woche`;
  }
  if (duration.days() > 1) {
    return `${duration.days()} Tagen`;
  }
  if (duration.days() > 0) {
    return `${duration.days()} Tag`;
  }
  if (duration.hours() > 1) {
    return `${duration.hours()} Stunden`;
  }
  if (duration.hours() > 0) {
    return `${duration.hours()} Stunde`;
  }
  if (duration.minutes() > 1) {
    return `${duration.minutes()} Minuten`;
  }
  if (duration.minutes() > 0) {
    return `${duration.minutes()} Minute`;
  }
  if (duration.seconds() > 5) {
    return `${duration.seconds()} Sekunden`;
  }
  return 'Gerade eben';
};