





































































import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import { IModal } from '@/interfaces/interfaces';
import {IBookmark} from '@/services/api/bookmark.service';

import BookmarksForm from '@/pages/Bookmarks/Bookmarks.form.vue';
import CreateBookmarkForm from '@/pages/Bookmarks/CreateBookmark.form.vue';
import ShareBookmarkForm from '@/pages/Bookmarks/ShareBookmark.form.vue';
import RemoveBookmarkForm from '@/pages/Bookmarks/RemoveBookmark.form.vue';
import BookmarkDetailModal from '@/pages/Bookmarks/BookmarkDetailModal.vue';

import EyeIcon from '@/assets/icon/ico-eye.svg';
import DownloadIcon from '@/assets/icon/ico-download.svg';
import PlusIcon from '@/assets/icon/ico-plus.svg';
import BackIcon from '@/assets/icon/back.svg';
import EditIcon from '@/assets/icon/edit.svg';
import DeleteIcon from '@/assets/icon/ico-delete.svg';
import MoveIcon from '@/assets/icon/verschieben.svg';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: {
    EyeIcon,
    DownloadIcon,
    PlusIcon,
    BackIcon,
    BookmarksForm,
    CreateBookmarkForm,
    ShareBookmarkForm,
    RemoveBookmarkForm,
    BookmarkDetailModal
  },
  computed: {...mapFields([
      'isModalBookmarkShareLoading',
      'isModalBookmarkListLoading',
      'bookmarkIdList',
      'editMode'
    ]),
  }
})

export default class BookmarksModals extends Vue {
  private PlusIcon = PlusIcon;
  public EditIcon = EditIcon;
  public DeleteIcon = DeleteIcon;
  public MoveIcon = MoveIcon;
  private bookmark: IBookmark | null | undefined = null;
  public isModalBookmarkShareLoading!: boolean;
  public isModalBookmarkListLoading!: boolean;
  public bookmarkIdList!: number[];
  private endStep: boolean = false;

  private modals: IModal[] = [
    {
      id: 'createBookmark',
      title: 'Neue Merkliste',
      maxWidth: '512px',
      component: CreateBookmarkForm
    },
    {
      id: 'replaceMedia',
      title: 'In Merkliste verschieben',
      maxWidth: '512px',
      component: BookmarksForm
    },
    {
      id: 'replaceMediaToNewBookmark',
      title: 'Neue Merkliste',
      maxWidth: '512px',
      component: CreateBookmarkForm
    },
    {
      id: 'renameBookmark',
      title: 'Merkliste umbenennen',
      maxWidth: '512px',
      component: CreateBookmarkForm
    },
    {
      id: 'shareBookmark',
      title: 'Merkliste freigeben',
      maxWidth: '692px',
      component: ShareBookmarkForm
    },
    {
      id: 'removeBookmark',
      title: 'Merkliste löschen',
      maxWidth: '512px',
      component: RemoveBookmarkForm
    },
    {
      id: 'bookmarkDetailModal',
      title: '',
      maxWidth: '768px',
      component: BookmarkDetailModal
    }
  ];

  private visibleData: boolean[] = [];

  private setDefaultVisibleData() {
    this.visibleData = [...Array(this.modals.length).fill(false)];
  }

  private getIndexById(id: string): number {
    return this.modals
      .findIndex((modal: IModal) => modal.id === id);
  }

  public openModal(id: string, bookmark?: IBookmark): void {
    this.bookmark = bookmark;
    this.setDefaultVisibleData();
    this.visibleData
      .splice(this.getIndexById(id), 1, true);
  }

  public closeModal(): void {
    this.setDefaultVisibleData();
    this.endStep = false;
  }

  public nextStep() {
    this.endStep = true;
  }

  public prevStep() {
    this.endStep = false;
  }

  public hasLoader(modal: IModal): boolean {
    return modal.id === 'shareBookmark' && this.isModalBookmarkShareLoading
      || modal.id === 'replaceMedia' && this.isModalBookmarkListLoading;
  }

  public toggleEditMode(): void {
    this.$store.dispatch('setEditModeAnimated', true);
    this.$store.dispatch('toggleEditMode');
  }
}
