



























import { Vue, Component } from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import Card from '@/components/UI/Card/Card.vue';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: { Card },
  computed: {
    ...mapFields([
      'mediaList',
      'mediaIdList',
      'editMode',
      'mediaListLoader',
    ])
  },
})
export default class BookmarkMediaList extends Vue {

}
